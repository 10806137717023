@import '~@which/seatbelt/src/styles/styles';

@mixin bottomMargin {
  margin-bottom: $sb-spacing-xl;

  @include sb-respond-to(large) {
    margin-bottom: $sb-spacing-3xl;
  }
}

.marginBottom {
  @include bottomMargin;
}

.nonFeatured {
  :last-child {
    border-bottom: none;
  }

  @include bottomMargin;
}
