@import '~@which/seatbelt/src/styles/styles';

.header {
  margin-top: $sb-spacing-xl;

  @include sb-respond-to(medium-up) {
    margin-top: $sb-spacing-3xl;
  }
}

.articles {
  margin-top: $sb-spacing-l;
}

:global(.background-grey) {
  background-color: $sb-colour-background-subtle;
  margin-top: $sb-spacing-l;
  margin-bottom: $sb-spacing-m;
}

.gamHtmlSnippet {
  text-align: center;
}

.localFooter {
  margin-top: $sb-spacing-3xl;
}
